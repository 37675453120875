// eslint-disable-next-line no-use-before-define
import { useQuery, useMutation } from '@apollo/client';
import { Fragment, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import { HeaderBar } from 'op-components';
import { UserContext, TenantContext } from 'op-contexts';
import { RoleType } from 'op-enums';
import { GET_RECENT_PATIENTS, GET_USER_PROFILE } from 'op-graphql/queries';
import dayjs from 'dayjs';

import { USER_FEATURE_QUERY, SET_USER_SURVEY } from './queries';
import { TENANT_CONFIG_QUERY } from './TenantConfigQuery';
import { EMRFeature, FeaturesList, UserFeature } from 'op-interfaces/User';
import { UserLanguageQueryData, TenantConfigQueryData } from 'op-interfaces/graph';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import './Base.scss';
import { isDemo } from 'op-utils';

const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

const { RO, MO } = RoleType;

const ROBase = ({ children }: PropsWithChildren): JSX.Element => {
  const [hasStaffId, setHasStaffId] = useState(false);
  const [navShow, setNavShow] = useState(true);
  const [panelShow, setPanelShow] = useState(true);
  const [primaryRole, setPrimaryRole] = useState('');
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isPractitioner, setIsPractitioner] = useState(false);
  const [isRegistrar, setIsRegistrar] = useState(false);
  const [features, setFeatures] = useState({} as FeaturesList);
  const [timezone, setTimezone] = useState(CurrentAppConfig?.DefaultTimezone);
  const [doseUnit, setDoseUnit] = useState('Gy');
  const [ariaDoseUnit, setAriaDoseUnit] = useState('Gy');
  const [mosaiqDoseUnit, setMosaiqDoseUnit] = useState('cGy');
  const [fractionUnit, setFractionUnit] = useState('#');

  const [setUserSurvey] = useMutation(SET_USER_SURVEY);

  // User profile and recent patients data
  const { data: userData, loading: userLoading } = useQuery<UserLanguageQueryData>(GET_USER_PROFILE);
  const { data: tenantConfigData } = useQuery<TenantConfigQueryData>(TENANT_CONFIG_QUERY);

  const { data: patientData } = useQuery(GET_RECENT_PATIENTS, {
    fetchPolicy: 'network-only',
    skip: ![RO, MO].includes(primaryRole),
  });
  const recentPatients = patientData && patientData.getRecentPatients ? patientData.getRecentPatients : [];

  const { data: featuresData } = useQuery(USER_FEATURE_QUERY, {
    skip: ![RO, MO].includes(primaryRole),
  });

  const isFeatureLoading = useCallback(() => {
    return !features;
  }, [features]);

  const getEMRFeature = useCallback(
    (featureName: string): EMRFeature | undefined => {
      if (features && features.featureList) {
        return features.featureList.find((item: any) => item?.feature?.name === featureName);
      }
      return undefined;
    },
    [features],
  );

  const getUserFeature = useCallback(
    (featureName: string): UserFeature | undefined => {
      if (features && features.userFeatureList) {
        return features.userFeatureList.find((item: any) => item?.feature?.name === featureName);
      }
      return undefined;
    },
    [features],
  );

  const hasFeature = useCallback(
    (featureName: string): boolean | undefined => {
      if (!features) {
        return undefined;
      }
      return Boolean(getEMRFeature(featureName)) || Boolean(getUserFeature(featureName));
    },
    [getEMRFeature, getUserFeature],
  );

  const toggleSearchModal = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  // Survicate initialization
  useEffect(() => {
    if (isDemo || !userData || !userData.user) return;
    const user = userData.user;

    (function initializeSurvicate(w) {
      const script = document.createElement('script');
      script.src = 'https://survey.survicate.com/workspaces/72ec103d56c4e992c84711e666197859/web_surveys.js';
      script.async = true;

      script.onload = () => {
        // Poll for survicate sva initialization
        const interval = setInterval(() => {
          if (window._sva) {
            window._sva.setVisitorTraits({
              user_id: user.id,
              surveys_displayed: user.surveysDisplayed?.join(', ') || '',
              name: user.name || '',
              primary_role: user.primaryRole || '',
              state: user.state || '',
              is_ro: user.isRo,
              region: REACT_APP_REGION || '',
              is_prod: user.isProd,
            });

            if (!window._svaAddedListeners) {
              window._svaAddedListeners = new Set();
            }

            // When a survey is displayed, store which user has seen it in the database
            if (!window._svaAddedListeners.has('survey_displayed')) {
              window._sva.addEventListener('survey_displayed', function (surveyId: any) {
                setUserSurvey({
                  variables: { surveyId },
                });
              });
            }

            window._svaAddedListeners.add('survey_displayed');

            clearInterval(interval); // Stop polling once traits are set
          }
        }, 1000);

        setTimeout(() => clearInterval(interval), 10000); // Stop after 10 seconds to prevent polling forever
      };

      const element = document.getElementsByTagName('script')[0];
      if (element && element.parentNode) {
        element.parentNode.insertBefore(script, element);
      }
    })(window);
  }, [userData]);

  useEffect(() => {
    if (userData && userData.user) {
      setPrimaryRole(userData.user.primaryRole);
      setHasStaffId(userData.user.hasStaffId);
      setTimezone(userData.user.timezone);
      setIsPractitioner(userData.user.isPractitioner);
      setIsRegistrar(userData.user.isRegistrar);
    }
    if (userData && userData.language) {
      dayjs.locale(userData.language.language);
    }
  }, [userData, userLoading]);

  useEffect(() => {
    if (tenantConfigData?.tenantConfig) {
      setDoseUnit(tenantConfigData?.tenantConfig?.doseUnit);
      setAriaDoseUnit(tenantConfigData?.tenantConfig?.ariaDoseUnit);
      setMosaiqDoseUnit(tenantConfigData?.tenantConfig?.mosaiqDoseUnit);
      setFractionUnit(tenantConfigData?.tenantConfig?.fractionUnit);
    }
  }, [tenantConfigData?.tenantConfig]);

  useEffect(() => {
    if (featuresData) {
      setFeatures(featuresData);
    }
  }, [featuresData]);

  if (userLoading || !userData) return <></>;

  return (
    <TenantContext.Provider
      value={{
        doseUnit,
        setDoseUnit,
        ariaDoseUnit,
        setAriaDoseUnit,
        mosaiqDoseUnit,
        setMosaiqDoseUnit,
        fractionUnit,
        setFractionUnit,
      }}>
      <UserContext.Provider
        value={{
          state: {
            hasStaffId,
            navShow,
            panelShow,
            primaryRole,
            timezone,
            isPractitioner,
            isRegistrar,
            features,
          },
          setHasStaffId,
          setNavShow,
          setPanelShow,
          setPrimaryRole,
          setTimezone,
          setFeatures,
          setIsPractitioner,
          setIsRegistrar,
          isFeatureLoading,
          getEMRFeature,
          getUserFeature,
          hasFeature,
        }}>
        <div
          className={classNames('ro-container', {
            'has-side-nav-active': navShow,
            'has-side-nav-inactive': !navShow,
            'has-side-panel-active': panelShow,
            'has-side-panel-inactive': !panelShow,
          })}
          id="ROContainer">
          <Fragment>
            <HeaderBar toggleSearchModal={toggleSearchModal} recentPatients={recentPatients} styledProps={true} />
            {children}
          </Fragment>
        </div>
      </UserContext.Provider>
    </TenantContext.Provider>
  );
};

export default ROBase;
