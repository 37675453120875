import React, { Component, ComponentClass, Fragment } from 'react';
import { gql } from '@apollo/client';

import { Region } from 'shared-components/enums';
import { Patient } from 'op-interfaces';
import { GraphQLHelper } from 'op-utils';
import { GraphUpdate, Dictionary } from 'shared-components/interfaces';
import { SavingStatus } from 'shared-components/enums';

import { ModalInfo, ModalSaveError } from 'op-components';
import { DocumentNode } from 'graphql';
const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

const region = REACT_APP_REGION;

export interface WithOPClinicForm {
  getPatientMutation: (
    patient: Patient,
    graphUpdate: GraphUpdate[],
    forceFetch?: object[],
    isObject?: boolean,
    updateObject?: object,
  ) => object;
  getSaveStatus: (pendingSaveCount: number, saveErrorCount: number) => SavingStatus;
  showModalIfLocked: (data: { patient: Patient }) => void;

  showSavingErrorModal: (isPSO: boolean, historyPush: (path: string, state?: any) => void) => void;
}

export const GET_APOLLO_CACHE = gql`
  {
    pendingSaveCount @client
    saveErrorCount @client
    registrationPagesViewed @client
  }
`;

interface State {
  modalIsOpen: boolean;
  modalTitle: string;
  modalText: string;
  errorModalIsOpen: boolean;
  errorForPSO: boolean;
  errorModalCallback?: () => void;
}

function withROCreatePatient<Props>(WrappedComponent: React.ComponentType<Props>): ComponentClass<Props> {
  return class extends Component<Props, State> {
    public constructor(props: Props) {
      super(props);

      this.state = {
        modalIsOpen: false,
        modalTitle: '',
        modalText: '',
        errorModalIsOpen: false,
        errorForPSO: false,
      };
    }

    public render(): JSX.Element {
      const { modalIsOpen, modalTitle, modalText, errorModalIsOpen, errorForPSO, errorModalCallback } = this.state;
      return (
        <Fragment>
          <ModalInfo
            title={modalTitle}
            text={modalText}
            isOpen={modalIsOpen}
            dismissFunction={(): void => {
              this.setState({
                modalIsOpen: false,
              });
            }}
          />
          <ModalSaveError isOpen={errorModalIsOpen} isPSO={errorForPSO} callbackRouting={errorModalCallback} />
          <WrappedComponent
            getPatientMutation={(
              patient: Patient,
              graphUpdate: GraphUpdate[],
              forceFetch?: object[],
              isObject?: boolean,
              updateObject?: object,
            ): object => {
              if (isObject && updateObject) {
                return this.getPatientMutationUsingObject(patient.id, updateObject, forceFetch);
              }
              return this.getPatientMutation(patient, graphUpdate, forceFetch);
            }}
            getSaveStatus={(pendingSaveCount: number, saveErrorCount: number): SavingStatus => {
              return this.getSaveStatus(pendingSaveCount, saveErrorCount);
            }}
            showModalIfLocked={(data: { patient: Patient }): void => {
              if (data && data.patient && data.patient.lock && data.patient.lock.readOnly) {
                const MODAL_TITLE =
                  region === Region.UK && data.patient.lock.lockedByName === 'system'
                    ? 'Patient already submitted'
                    : 'Record in use';
                const RECORD_IN_USE_BY = 'This patient record is currently in use by';
                const RECORD_IN_USE_NO_VIEW =
                  'and cannot be edited until it is released by the user. Any changes made to this record will not be saved';
                const inUseBy = data.patient.lock && data.patient.lock.lockedByName;
                const modalText = `${RECORD_IN_USE_BY} ${inUseBy} ${RECORD_IN_USE_NO_VIEW}`;
                this.showModal(MODAL_TITLE, modalText);
              }
            }}
            // eslint-disable-next-line
            showSavingErrorModal={(isPSO: boolean, historyPush: (path: string, state?: any) => void): void => {
              // Create the call back routing function here so that all the other pages do not need to have all this code copied and pasted.
              const callbackRouting = (): void => {
                if (isPSO) {
                  historyPush('/search');
                } else {
                  historyPush('/patient');
                }
              };
              this.showSavingErrorModal(isPSO, callbackRouting);
            }}
            {...this.props}
          />
        </Fragment>
      );
    }

    /**
     * Get the GQL varibles to update a patient
     * @param {string} patientId The patient id for the patient object you wish to update
     * @param {object} updateObject The object that should be used to update the patient with
     * @param {object[]} forceFetch Optional, the values that should be forced fetched.
     * @param {boolean} addTypeName Optional, the type name should be added if the update object has multiple leves
     * @return {object} Varibles of updated patient data to use in update patient mutation
     */
    private getGQLVariablesUsingObject = (
      patientId: string,
      updateObject: object,
      forceFetch?: any[],
      addTypeName?: boolean,
    ): object => {
      // Always include the id
      const variables: { [key: string]: any } = {
        id: patientId,
      };

      for (const updateEntry of Object.entries(updateObject)) {
        const [key, value] = updateEntry;
        // If the value is an object, need to deep copy the object across instead
        if (typeof value === 'object') {
          variables[key] = { ...value };
        } else {
          variables[key] = value;
        }

        // Only add the type name if required, since other functions that don't require use this method as well.
        if (addTypeName && (key === 'emergencyContact' || key === 'nextOfKinContact')) {
          variables[key].__typename = 'AlternateContactInputType';
        }
      }

      if (forceFetch && forceFetch.length > 0) {
        // Loop through the force fetch item to build the object
        for (const forceFetchItem of forceFetch) {
          Object.keys(forceFetchItem).forEach((key): void => {
            variables[key] = forceFetchItem[key];
          });
        }
      }

      return variables;
    };

    /**
     * Get the GQL varibles to update a patient
     * @param {Patient['patient']} patient The patient object you wish to update
     * @param {GraphUpdate[]} graphUpdate An array of items on the patient you wish to update
     * @return {object} Varibles of updated patient data to use in update patient mutation
     */

    private getgqlVariables(patient: Patient, graphUpdate: GraphUpdate[], forceFetch?: any[]): object {
      // Always include the patient id
      const variables: any = {
        id: patient.id,
      };
      // Add any varibles in order to update a patient
      for (const update of graphUpdate) {
        variables[update.key] = update.value;
      }

      if (forceFetch && forceFetch.length > 0) {
        // Loop through the force fetch item to build the object
        for (const forceFetchItem of forceFetch) {
          Object.keys(forceFetchItem).forEach((key): void => {
            variables[key] = forceFetchItem[key];
          });
        }
      }

      return variables;
    }

    /**
     * Generates the patient mutation using an object instead of graph update objects
     * @param {string} patientId The patient id for the patient that is being updated.
     * @param {object} updateObject The object containing the values that should be updated.
     * @param {object[]} forceFetch Optional, an array containing objects that need to be force fetched. Currently only supports 2 level deep objects.
     * @returns {object} The mutation object that is used to save to Apollo.
     */
    private getPatientMutationUsingObject = (
      patientId: string,
      updateObject: object,
      forceFetch?: object[],
    ): object => {
      const variables = this.getGQLVariablesUsingObject(patientId, updateObject, forceFetch, true);
      const patientResponse = { ...variables, __typename: 'PatientType' };

      return {
        mutation: this.getGQLUpdatePatientUsingObject(updateObject, forceFetch),
        variables: this.getGQLVariablesUsingObject(patientId, updateObject, forceFetch),
        optimisticResponse: {
          updatePatient: {
            patient: patientResponse,
            errors: null,
            __typename: 'UpdatePatient',
          },
        },
      };
    };

    /**
     * Get the mutation to update the patient via Apollo
     * Includes an optimisticResponse which will assume the Apollo call was sucesfull and update the UI immediately
     * If the call was not sucessfull the UI will revert to match the response from the server (Graphene)
     * @param patient The patient object you wish to update
     * @param graphUpdate An array of items on the patient you wish to update
     * @param forceFetch Updating certain values in a forced manner, even if its not updated.
     * @return {object} Mutation that Apollo will call to update a patient.
     */
    private getPatientMutation(patient: Patient, graphUpdate: GraphUpdate[], forceFetch?: object[]): object {
      const patientResponse = { ...this.getgqlVariables(patient, graphUpdate, forceFetch), __typename: 'PatientType' };

      return {
        mutation: this.getgqlUpdatePatient(graphUpdate, forceFetch),
        variables: this.getgqlVariables(patient, graphUpdate, forceFetch),
        optimisticResponse: {
          updatePatient: {
            patient: patientResponse,
            errors: null,
            __typename: 'UpdatePatient',
          },
        },
      };
    }

    /**
     * Returns the gql string for updating a patient passing an update object rather than graph update items. This allows for object in object updates rather than flattening.
     * @param {object} updateObject The object that is going to update the patient with.
     * @param {object[]} forceFetch Optional, the object that is being force fetched without any update value.
     */
    private getGQLUpdatePatientUsingObject = (updateObject: object, forceFetch?: object[]): DocumentNode => {
      const mutationParameters = GraphQLHelper.mapObjectToMutationParams(updateObject);
      const functionParameters = GraphQLHelper.mapObjectToFunctionParams(updateObject);

      const functionValues = GraphQLHelper.flattenObjectToQueryString(
        updateObject,
        undefined,
        this.buildForceFetchString(forceFetch),
      );

      const graphMutation = `mutation UpdatePatient(${mutationParameters}) {
        updatePatient(${functionParameters}) {
          patient ${functionValues}
          errors
        }
      }`;
      return gql(graphMutation);
    };

    /**
     * Returns the gql string updating a patient
     * Example input:
     * graphUpdate = [{
     *  key: 'preferredName',
     *  value: 'Bobby',
     *  type: 'String',
     * }, {
     *  key: 'namePrefix',
     *  value: 'Mr',
     *  type: 'String',
     * }];
     * Will result in the following output:
     * mutationParmaters = $preferredName: String, $namePrefix: String
     * functionParamaters = preferredName: $preferredName, namePrefix: $namePrefix'
     * functionValues = preferredName
     *                  namePrefix
     * @param {GraphUpdate[]} graphUpdate An array of items on the patient you wish to update
     * @param {string[]} forceFetch An array of optional strings that forces a refetch for
     * @returns {string} GQL mutation to update a patient for a given update
     */
    private getgqlUpdatePatient(graphUpdate: GraphUpdate[], forceFetch?: object[]): DocumentNode {
      const mutationParmaters = graphUpdate.map((e): string => `$${e.key}: ${e.type}`).join(', ');
      const functionParamaters = graphUpdate.map((e): string => `${e.key}: $${e.key}`).join(', ');
      let functionValues = graphUpdate.map((e): string => `${e.key}`).join('\n');
      functionValues += this.buildForceFetchString(forceFetch);

      const graphMutation = `mutation UpdatePatient($id: ID!, ${mutationParmaters}) {
      updatePatient(id: $id, ${functionParamaters}) {
        patient {
          id
          ${functionValues}
        }
        errors
      }
     }`;
      return gql(graphMutation);
    }

    private getSaveStatus = (pendingSaveCount: number, saveErrorCount: number): SavingStatus => {
      let saveStatus = SavingStatus.SAVED;
      if (pendingSaveCount > 0) {
        saveStatus = SavingStatus.SAVING;
      }
      if (saveErrorCount > 0) {
        saveStatus = SavingStatus.FAILED;
      }
      return saveStatus;
    };

    private showModal = (title: string, text: string): void => {
      this.setState({ modalIsOpen: true, modalTitle: title, modalText: text });
    };

    private showSavingErrorModal = (isPSO: boolean, callbackRouting: () => void): void => {
      this.setState({ errorModalIsOpen: true, errorForPSO: isPSO, errorModalCallback: callbackRouting });
    };

    private buildForceFetchString(forceFetch?: object[]): string {
      const isObjectAnObject = (objectToCheck: object | string | boolean): boolean => {
        const typeOfObject = typeof objectToCheck;
        return typeOfObject === 'object' && typeOfObject !== null;
      };

      let returnString = '';
      if (forceFetch && forceFetch.length > 0) {
        returnString += '\n';
        forceFetch.forEach((forceFetchItem): void => {
          // This will currently only handle 2 levels deep, any more and it will need to be updated
          const forceKeys = Object.keys(forceFetchItem);
          if (isObjectAnObject(forceFetchItem) && forceKeys.length > 0) {
            // Convert the force fetch item to an object with keys
            const forceFetchObjects = forceFetchItem as Dictionary;
            for (const forceKey of forceKeys) {
              const forceFetchObject = forceFetchObjects[forceKey];
              returnString += `${forceKey}`;

              // Determine if there is another level of keys
              const secondForceKeys = Object.keys(forceFetchObject);
              if (isObjectAnObject(forceFetchObject) && secondForceKeys.length > 0) {
                returnString += ' {\n';
                for (const secondForceKey of secondForceKeys) {
                  returnString += `${secondForceKey}\n`;
                }
                returnString += '}\n';
              } else {
                returnString += '\n';
              }
            }
          } else {
            // It is not an object that needs to be dived into
            returnString += `${forceFetchItem}\n`;
          }
        });
      }

      return returnString;
    }
  };
}

export default withROCreatePatient;
