// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react';
import { ToggleButtons } from 'shared-components/components/FormFields';
import { StandardDialog } from 'shared-components/components';
import { Stack, Typography } from '@mui/material';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
import { ROPatientCarePlanRoute } from 'op-pages/RO/Careplan/Interface';

const BOOLEAN_OPTIONS = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

interface Props {
  isOpen: boolean;
  submitFunction: (value: string) => void;
}

export interface ButtonInterface {
  text: string;
  value: string;
}

const UPDATE_CAREPLAN_SIMULATION_SUBMISSION = gql`
  mutation updateCareplanSimulationSubmission($careplanId: ID!, $simulationSubmission: Boolean!) {
    updateCareplanSimulationSubmission(careplanId: $careplanId, simulationSubmission: $simulationSubmission) {
      careplan {
        id
      }
    }
  }
`;

const ModalSimulationSubmission = (props: Props): JSX.Element => {
  const { isOpen, submitFunction } = props;
  const [currentValue, setCurrentValue] = useState<string>('');
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const match = useRouteMatch<ROPatientCarePlanRoute>();
  const { id: patientId, careplanId } = match.params;
  // This is to update the careplan simulation re-submission status
  // after CPOT changes are made. this will be reflected in pdf.
  const [updateCareplan] = useMutation(UPDATE_CAREPLAN_SIMULATION_SUBMISSION);
  const checkSimSubmission = () => {
    if (currentValue === '') {
      setIsInvalid(true);
      return;
    }
    updateCareplan({
      variables: {
        careplanId: careplanId,
        simulationSubmission: currentValue === 'yes',
      },
    }).then(() => {
      submitFunction(currentValue);
    });
  };

  return (
    <StandardDialog
      open={isOpen}
      title="Would you like to request a new simulation?"
      onSubmit={checkSimSubmission}
      submitText="Confirm">
      <Stack gap={2} paddingY={2}>
        <Typography>
          A change to the simulation or treatment sites has occured. Please confirm if a new simulation is required*
        </Typography>
        <ToggleButtons
          id={'modal-simulation-submission'}
          options={BOOLEAN_OPTIONS}
          fullWidth
          value={currentValue}
          handleChange={(value: string): void => {
            setIsInvalid(false);
            setCurrentValue(value);
          }}
          error={isInvalid}
        />
      </Stack>
    </StandardDialog>
  );
};

export default ModalSimulationSubmission;
