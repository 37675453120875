import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useRouteMatch, useHistory, Prompt } from 'react-router-dom';

import { ROPatientCarePlanRoute, PreviewDocuments } from '../Interface';
import ROPatientCarePlanPageFooter from '../Footer';
import { useMutation, useQuery } from '@apollo/client';
import { SUBMIT_PRESCRIPTION, TRIGGER_CPOT, GET_SUBMISSION_PREVIEW_DOCUMENTS, REVERT_CPOT } from '../Queries';
import { CareplanBanners, changesFromLastEdited, hasSubmitted } from '../Banner/Banner';
import { GET_CAREPLAN, USER_DATA } from '../Queries';
import './SubmissionPage.scss';
import { CurrentAppConfig } from '../AppConfig';
import { LoadingSpinner, StandardDialog } from 'shared-components/components';
import CareplanContainer from '../Container';
import { UserContext } from 'op-contexts';
import ModalCpotCancel from '../ModalCpotCancel/ModalCpotCancel';
import { ModalSimulationSubmission, ROSidePanel } from 'op-components';
import { Region } from 'shared-components/enums';
import { usePreventUnload } from 'shared-components/utils/CustomHooks';
import { CAREPLAN_PAGES } from '../Constants';
import { useWindowSize } from 'shared-components/utils/CustomHooks';
import { LARGE_DISPLAY_SIZE } from 'shared-components/enums';
import { GET_PEER_REVIEW, GET_DIAGNOSIS_FROM_CAREPLAN } from '../SidePanel/SidePanelQueries';
import { Logger } from 'shared-components/utils';
import { logMissingDiagnosisCode } from '../Common';
import { Typography } from '@mui/material';
import { Stack, Card, CardContent, Divider, ListItemButton } from '@mui/material';
import SubmittedPanel from './SubmittedPanel';
import RequestTemplateModal from './RequestTemplateModal';
import dayjs from 'dayjs';

const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

const region = REACT_APP_REGION;

const logger = new Logger('SubmissionPage.tsx');
const LABEL_REVERT_CPOT = 'Revert to Previous Prescription';
const LABEL_CANCEL_CPOT = 'Cancel';
const LABEL_CPOT = 'Change prescription on treatment';

const isCPOTCareplanRevertAllowed = (careplan: any): boolean => {
  // if careplan is not CPOT, return true
  if (careplan?.changeType !== 'CPOT') return true;
  const submittedAt = dayjs(careplan?.submittedAt);
  const hoursSinceSubmission = dayjs().diff(submittedAt, 'hours');
  return careplan?.changeType === 'CPOT' && hoursSinceSubmission < 168; // 7 days
};

const ROPatientTreatmentSubmissionPage = (): JSX.Element => {
  /**
   * @description
   * This page is responsible for displaying the submission page for the careplan.
   * It displays the careplan in a PDF format and allows the user to submit the careplan to MOSAIQ.
   * Scenarios:
   * 1. Plan Aim submission
   *    - show tooltip if there are autodocs to be submitted
   * 2. Prescription submission
   * 3. Prescription resubmission with simulation resubmission
   * 4. Prescription Directive submission
   * 5. CPOT submission
   * 6. CPOT cancellation
   * 7. UK specific prescription resubmission
   */
  const windowSize = useWindowSize();
  const isSmallDevice = windowSize.width < LARGE_DISPLAY_SIZE;
  const history = useHistory();
  const [state, setState] = useReducer((state: any, changeState: any): any => ({ ...state, ...changeState }), {
    submitting: false,
    submissionType: CurrentAppConfig.SubmissionPage.PlanAimSubmissionType,
    loading: false,
    error: false,
  });
  const userDetails = useContext(UserContext);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showStartModal, setShowStartModal] = useState(false);
  const [showSimSubmissionModal, setShowSimSubmissionModal] = useState<boolean | undefined>(undefined);
  const [shouldResubmitSimulation, setShouldResubmitSimulation] = useState(true);
  const showSpecialPhysicsConsultPage = CurrentAppConfig.SpecialPhysicsConsultVisible;
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState('');
  const [showRequestTemplateModal, setShowRequestTemplateModal] = useState(false);

  const match = useRouteMatch<ROPatientCarePlanRoute>();
  const { id: patientId, careplanId } = match.params;

  const [triggerCpot] = useMutation(TRIGGER_CPOT, {
    awaitRefetchQueries: true,
    variables: {
      id: careplanId,
      cpotTriggered: true,
    },
    refetchQueries: [{ query: GET_CAREPLAN, variables: { id: careplanId } }],
  });
  const [cancelCpot] = useMutation(TRIGGER_CPOT, {
    awaitRefetchQueries: true,
    variables: {
      id: careplanId,
      careplanStatus: 'Prescription',
      cpotTriggered: false,
    },
    refetchQueries: [{ query: GET_CAREPLAN, variables: { id: careplanId } }],
  });
  const [revertCpot] = useMutation(REVERT_CPOT, {
    awaitRefetchQueries: true,
    variables: {
      id: careplanId,
    },
    refetchQueries: [
      { query: GET_CAREPLAN, variables: { id: careplanId } },
      { query: GET_SUBMISSION_PREVIEW_DOCUMENTS, variables: { careplanId } },
    ],
  });

  const [submitPrescription] = useMutation(SUBMIT_PRESCRIPTION, {
    refetchQueries: [
      { query: GET_CAREPLAN, variables: { id: careplanId } },
      { query: GET_PEER_REVIEW, variables: { careplanId } },
      { query: GET_SUBMISSION_PREVIEW_DOCUMENTS, variables: { careplanId } },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmission = (submissionType: string) => {
    setState({ submitting: true, loading: true });
    submitPrescription({
      variables: { id: careplanId, status: submissionType, resubmitSimulation: shouldResubmitSimulation },
    }).then(() => {
      setState({ submitting: false, loading: false });
    });

    if (window._sva) {
      window._sva.invokeEvent('showSurvey');
    }
  };

  useQuery(GET_DIAGNOSIS_FROM_CAREPLAN, {
    fetchPolicy: 'network-only',
    variables: { careplanId: careplanId },
    onCompleted: (data: any): void => {
      logMissingDiagnosisCode(logger, data.careplan, 'Submission');
    },
  });

  const { data } = useQuery(GET_CAREPLAN, {
    fetchPolicy: 'network-only',
    variables: { id: careplanId },
  });
  const {
    data: submissionPreviewData,
    loading: previewDataLoading,
    refetch: previewDocsRefetch,
  } = useQuery(GET_SUBMISSION_PREVIEW_DOCUMENTS, {
    variables: { careplanId },
    skip: !data,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const { data: userData } = useQuery(USER_DATA);

  useEffect(() => {
    if (submissionPreviewData) {
      if (submissionPreviewData.previewDocuments.length === 0) {
        setState({ error: true, loading: false });
        return;
      }
      setSelectedDocumentUrl(submissionPreviewData.previewDocuments[0].previewUrl);
    }
  }, [submissionPreviewData, previewDataLoading]);
  const careplanStatus = data?.careplan?.careplanStatus;
  const unloadPrevented =
    ['DRAFT', 'CPOT'].includes(careplanStatus) ||
    Boolean(hasSubmitted(data?.careplan, userDetails.state.timezone) && changesFromLastEdited(data?.careplan));

  // check if simulation changes have been made and modal needs enabling. only once, after that the modal
  // will not appear until page is refreshed or navigated to another page
  useEffect(() => {
    if (data?.careplan?.simulationModification && !state.submitting && showSimSubmissionModal === undefined) {
      setShowSimSubmissionModal(true);
    }
  }, [data?.careplan?.simulationModification, state, showSimSubmissionModal]);

  // prevent user from leaving page if careplan has not been submitted
  usePreventUnload(unloadPrevented);
  if (!userData) return <></>;
  const { user } = userData;

  const cpotTriggered = careplanStatus === 'CPOT';
  const disableAllNav = data?.careplan?.changeType === 'CPOT' && data?.careplan?.careplanStatus === 'PRESCRIPTION';
  const resetHidden =
    !['PRESCRIPTION', 'CPOT'].includes(careplanStatus) ||
    data?.careplan?.diagnosisModification ||
    data?.careplan?.prescriptionModification ||
    data?.careplan?.simulationModification ||
    !CurrentAppConfig.SubmissionPage.CPOTEnabled ||
    careplanStatus === 'CPOT';

  const ukCiedDisabled =
    region === Region.UK &&
    data &&
    data.careplan.cied === 'yes' &&
    (data.careplan.ciedPatientDependancy === 'yes' || data.careplan.ciedLinacRequired === 'yes');
  const planAimSubmission: boolean =
    data &&
    data.careplan &&
    ['PRESCRIPTION', 'PLAN_AIM', 'PRESCRIPTION_DIRECTIVE'].includes(data.careplan.careplanStatus);
  const requestChangesDisabled = Boolean(data?.careplan?.templateChangeRequested) && region === Region.US;
  const resetText = (): string => {
    if (CurrentAppConfig.RequestTemplateChangeActive) return 'Request as template';
    if (careplanStatus === 'PRESCRIPTION' && !(region === Region.UK)) {
      return data?.careplan?.changeType === 'CPOT' ? LABEL_REVERT_CPOT : LABEL_CPOT;
    }
    if (cpotTriggered) return LABEL_CANCEL_CPOT;
    return '';
  };
  const footerNextDisabled =
    careplanStatus === 'PRESCRIPTION' ||
    ukCiedDisabled ||
    disableAllNav ||
    cpotTriggered ||
    (planAimSubmission && !changesFromLastEdited(data?.careplan)) ||
    user.isShowcaseUser ||
    state.loading;

  const footerAdditionRightDisabled =
    (careplanStatus === 'PRESCRIPTION' && !changesFromLastEdited(data?.careplan)) ||
    user.isShowcaseUser ||
    user.isRegistrar ||
    state.loading ||
    disableAllNav;

  const nextText = CurrentAppConfig.SubmissionPage.PlanAimSubmissionType; // e.g. Plan Aim
  const nextTextId = nextText.toLowerCase().replace(' ', '-'); // e.g. plan-aim
  // has autodoc documents
  const hasAutoDocDocuments = submissionPreviewData?.previewDocuments.some(
    (doc: PreviewDocuments) => !['Prescription', 'Simulation Request', 'Peer Review'].includes(doc.documentType),
  );
  const isCPOTRevertAllowed = isCPOTCareplanRevertAllowed(data?.careplan);
  const revertCpotAction = () => {
    setState({ submitting: true, loading: true });
    revertCpot().then(() => {
      setState({ submitting: false, loading: false });
    });
  };
  const footerOptions = {
    nextText: nextText,
    nextTextId: nextTextId,
    nextHidden: CurrentAppConfig.SubmissionPage.PlanAimHidden,
    nextTooltip: !footerNextDisabled && hasAutoDocDocuments && 'Only Prescription/SIM request will be submitted', // Only show tooltip if there are autodocs to be submitted
    // TODO: revert hideLeftButton
    hideLeftButton: careplanStatus !== 'PRESCRIPTION',
    //hideLeftButton: true,
    onReset: () => {
      if (CurrentAppConfig.RequestTemplateChangeActive) {
        setShowRequestTemplateModal(true);
      } else if (careplanStatus === 'PRESCRIPTION' && data?.careplan?.changeType === 'CPOT') {
        setShowCancelModal(true);
      } else if (!cpotTriggered) {
        setShowStartModal(true);
      } else {
        setShowCancelModal(true);
      }
    },
    resetHidden: resetHidden ? !(region === Region.US) : false,
    resetDisabled: !isCPOTRevertAllowed || user.isRegistrar || requestChangesDisabled,
    resetText: resetText(),
    resetTooltip: !isCPOTRevertAllowed ? 'Reversion to previous prescription not possible after 7 days' : '',
    additionalLeftText: 'REQUEST AS TEMPLATE',
    nextDisabled: footerNextDisabled,
    backDisabled: disableAllNav,
    nextLoading: state.submitting && state.submissionType === CurrentAppConfig.SubmissionPage.PlanAimSubmissionType,
    additionRightText: 'Prescription',
    additionRightTextId: 'prescription',
    additionRightDisabled: footerAdditionRightDisabled,
    onNext: (): void => {
      handleSubmission(CurrentAppConfig.SubmissionPage.PlanAimSubmissionType);
    },
    additionRightLoading: state.submitting && state.submissionType === 'Prescription',
    onAdditionRight: () => {
      handleSubmission('Prescription');
    },
  };
  const additionalURLparam =
    hasSubmitted(data?.careplan, userDetails.state.timezone) && !changesFromLastEdited(data?.careplan)
      ? '?submission=True'
      : '';
  const zoomLevel = isSmallDevice ? '100' : '130'; // large desktop set to 130%, otherwise 100%
  // filter simulation request documents if the simulation resubmit is disabled
  const siteGroupDocDict =
    submissionPreviewData?.previewDocuments.reduce((prevDict: Record<string, any>, document: PreviewDocuments) => {
      if (!prevDict[document.siteGroupId]) {
        prevDict[document.siteGroupId] = [];
      }
      prevDict[document.siteGroupId].push(document);
      return prevDict;
    }, {}) || {};
  const docList = Object.keys(siteGroupDocDict).map((siteGroupId) => {
    return {
      name: siteGroupDocDict[siteGroupId][0].siteGroupName,
      docs: siteGroupDocDict[siteGroupId].map((doc: any) => {
        return {
          documentType: doc.documentType,
          previewUrl: doc.previewUrl,
        };
      }),
    };
  });
  const isPreview = (changesFromLastEdited(data?.careplan) || careplanStatus === 'DRAFT') && docList.length > 0;
  return (
    <>
      <Prompt
        when={unloadPrevented}
        message={(location) => {
          return location.pathname.includes(showSpecialPhysicsConsultPage ? '/specialPhysicsConsult' : '/voluming')
            ? true
            : 'This careplan has NOT been submitted. Are you sure you want to leave page without submitting?';
        }}
      />

      {showRequestTemplateModal && (
        <RequestTemplateModal
          open={showRequestTemplateModal}
          careplanId={careplanId}
          onClose={() => setShowRequestTemplateModal(false)}
        />
      )}

      <div className="main-container-parent-wrapper">
        <div className="main-container-wrapper">
          <CareplanBanners data={data} refetchQueriesList={[{ query: GET_CAREPLAN, variables: { id: careplanId } }]} />
          <CareplanContainer className={'pdf-submission-container'}>
            {isPreview ? (
              <Stack direction="row" spacing={2} height={1}>
                <Card
                  variant="outlined"
                  sx={{ width: '240px', minWidth: '240px', height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardContent
                    sx={{
                      flex: '1 1 auto',
                      display: 'flex',
                      flexDirection: 'column',
                      overflowY: 'auto',
                      padding: '0px',
                    }}>
                    <div style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1, padding: '0px' }}>
                      <Typography data-testid="page-header" variant="h6" color="text.primary" padding="16px">
                        Review & Submit
                      </Typography>
                      <Divider />
                    </div>
                    <Stack overflow="auto" flex="1 1 auto">
                      {docList.map((doc, index) => {
                        return (
                          <div key={index}>
                            {doc.name && (
                              <Typography variant="subtitle2" color="text.primary" padding="8px 0px 8px 20px">
                                {doc.name}
                              </Typography>
                            )}
                            {doc.docs.map((doc: PreviewDocuments, index: number) => {
                              return (
                                <ListItemButton
                                  key={index}
                                  onClick={() => setSelectedDocumentUrl(doc.previewUrl)}
                                  sx={{
                                    borderLeftColor:
                                      doc.previewUrl === selectedDocumentUrl ? 'primary.main' : 'transparent',
                                    borderLeftWidth: '4px',
                                    borderLeftStyle: 'solid',
                                    padding: '8px 0px 8px 16px',
                                    borderRadius: '4px 4px 4px 4px',
                                    backgroundColor:
                                      doc.previewUrl === selectedDocumentUrl ? 'secondary.light' : 'default',
                                  }}>
                                  <Typography variant="body2" color="text.primary">
                                    {doc.documentType}
                                  </Typography>
                                </ListItemButton>
                              );
                            })}
                            {doc.name && <Divider />}
                          </div>
                        );
                      })}
                    </Stack>
                  </CardContent>
                </Card>
                <Card variant="outlined" sx={{ width: '100%', height: '100%', padding: '0px' }}>
                  {!previewDataLoading && selectedDocumentUrl ? (
                    <SubmittedPanel url={selectedDocumentUrl} zoomLevel={parseInt(zoomLevel)} />
                  ) : (
                    <LoadingSpinner
                      loadingText={'Loading Submission'}
                      subtitle={'Please wait while we set things up for you'}
                    />
                  )}
                </Card>
              </Stack>
            ) : (
              <SubmittedPanel
                url={
                  state.submitting
                    ? ''
                    : `/ro_portal/careplan/${careplanId}/prescription.pdf${additionalURLparam}&type=${careplanStatus}#zoom=${zoomLevel}`
                }
                zoomLevel={parseInt(zoomLevel)}
              />
            )}
          </CareplanContainer>
          <ROPatientCarePlanPageFooter
            onBack={(): void => {
              history.push(
                `/radiation/patient/${patientId}/careplan/${careplanId}/${
                  showSpecialPhysicsConsultPage ? CAREPLAN_PAGES.SPECIAL_PHYSICS_CONSULT : CAREPLAN_PAGES.VOLUMING
                }/last`,
              );
            }}
            {...footerOptions}
          />
          <ModalSimulationSubmission
            isOpen={showSimSubmissionModal !== undefined && showSimSubmissionModal}
            submitFunction={(value: string) => {
              setShowSimSubmissionModal(false);
              setShouldResubmitSimulation(value === 'yes');
              // reset the pdf preview to load latest simulation request changes
              setSelectedDocumentUrl('');
              previewDocsRefetch();
            }}
          />
          <ModalCpotCancel
            isOpen={showCancelModal}
            submitFunction={() => (resetText() === LABEL_REVERT_CPOT ? revertCpotAction() : cancelCpot())}
            dismissFunction={() => setShowCancelModal(false)}
            modalType={cpotTriggered ? 'cancel' : 'revert'}
          />
          <StandardDialog
            title="Change prescription on treatment"
            open={showStartModal}
            onClose={() => setShowStartModal(false)}
            secondarySubmitText="Simulation"
            closeText="Cancel"
            onSecondarySubmit={() => {
              triggerCpot();
              history.push(`/radiation/patient/${patientId}/careplan/${careplanId}/simulation`);
            }}
            onSubmit={() => {
              triggerCpot();
              history.push(`/radiation/patient/${patientId}/careplan/${careplanId}/prescription`);
            }}
            submitText={'Prescription'}>
            <Typography paddingY={2}>Would you like to re-request a simulation or change in prescription?</Typography>
          </StandardDialog>
        </div>
        <ROSidePanel />
      </div>
    </>
  );
};

export default ROPatientTreatmentSubmissionPage;
