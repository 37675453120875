// Has to be the first import for I.E
import 'regenerator-runtime/runtime';
import { createRoot } from 'react-dom/client';

// eslint-disable-next-line no-use-before-define
import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ThemeContext } from './contexts';

import 'shared-components/Styles/Main.scss';

import { SetMomentLocale } from 'shared-components/utils';
import Favicon from 'react-favicon';
import { NavigationContextProvider, ROBase, Refresh, RegistrationContextProvider, region } from 'op-components';
import { isDemo } from 'op-utils';
import { AssessmentProvider } from 'op-pages/OP/PatientFormSummary/context';
import { Region } from 'shared-components/enums';

import {
  AppSelector,
  CovidContainer,
  CreatePatient,
  DTContainer,
  DocumentsPageRouter,
  ErrorPage,
  HAContainer,
  HomeRegistrationLogin,
  HomeRegoEndSession,
  InformationNotice,
  LinkExpired,
  LogoutOP,
  MOBase,
  MOCreatePatient,
  MODashboard,
  MOPatientCarePlanPage,
  MOPatientSummary,
  ManagementPlan,
  Notes,
  OutcomesPage,
  PSODashboard,
  PXChangePassword,
  PXForgotPassword,
  PXLinkExpired,
  PXLogin,
  PXResetLink,
  PXResetPassword,
  PXSignup,
  PatientFormSummaryApollo,
  PatientNavigationApollo,
  PreCtChartCheckApollo,
  PreCtTriageApollo,
  ROCreatePatient,
  ROPatientCarePlanPage,
  ROPatientLabs,
  ROPatientSummaryPage,
  RegistrationAU,
  RegistrationAddressApollo,
  RegistrationBasicApollo,
  RegistrationComplete,
  RegistrationConflictsApollo,
  RegistrationContactDetailsApollo,
  RegistrationFileAttachmentsApollo,
  RegistrationInformationNoticeApollo,
  RegistrationInsuranceApollo,
  RegistrationLoginApollo,
  RegistrationUS,
  SSOPage,
  SessionTimeout,
  Summary,
  UKAppointmentsDashboardApollo,
  UKHomePage,
  UKPatientSearch,
  UKRegistrationDemographicsApollo,
  UKRegistrationGPApollo,
  UKRegistrationSummaryApollo,
  PXAppointments,
  MappingUpdate,
  PXTermsAndConditions,
  PXFAQContentContainer,
  PXFAQHome,
  PXContentContainer,
  PXInformation,
  PXFeedback,
  PXPatientDetails,
  PXMultifactorAuth,
  PXHome,
  InsightsDashboard,
  ROPatientTracker,
  ROPatientPathway,
  OnTreatmentReview,
  OnTreatmentReviewForm,
} from 'op-pages';
import UKRegistrationAltContactApollo from 'op-pages/OP/RegistrationForm/UKRegistrationAltContact/UKRegistrationAltContactApollo';
import SentryUser from 'shared-components/components/SessionData/SentryUser';
import AppVersion from 'shared-components/components/SessionData/AppVersion';
import * as Sentry from '@sentry/react';
import { Logger } from 'shared-components/utils';
import { FormContextProvider } from './pages/OP/PatientNavigation/context';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { datadogRum } from '@datadog/browser-rum';
import DatadogUserInfo from 'shared-components/components/DatadogUserInfo/DatadogUserInfo';
import { GCTheme, LumonusTheme } from 'theme';
import Dashboard from 'op-pages/RO/Dashboard/Dashboard';
import OutcomeFormPage from 'op-pages/RO/Outcomes/OutcomeFormPage';
import { ApolloProviderWithError } from 'op-utils/ApolloSetup';
import { ErrorModalContextProvider, useErrorModalContext } from 'op-contexts/ErrorModalContext/ErrorModalContext';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/en'; // Import English locale as an example
import 'dayjs/locale/en-au'; // Import English (Australia) locale as an example
import 'dayjs/locale/en-gb'; // Import English (Great Britain) locale as an example
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(timezone);
// Function to get the browser's locale and set it in dayjs
const setDayjsLocale = () => {
  const browserLocale = navigator.language || 'en';
  const supportedLocales = ['en', 'en-au', 'en-gb'];
  const locale = supportedLocales.includes(browserLocale) ? browserLocale : 'en';
  dayjs.locale(locale);
};

setDayjsLocale();
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
const SentryRoute = Sentry.withSentryRouting(Route);

const SENTRY_DSN = import.meta.env.SENTRY_DSN;
if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 0.2,
  });
}

const logger = new Logger('index');
const defaultTheme = import.meta.env.REACT_APP_THEME === 'Lumonus' ? LumonusTheme : GCTheme;

// datadog rum init
const ALLOWED_ENVS = ['production', 'demo'];
if (ALLOWED_ENVS.includes(import.meta.env.DATADOG_RUM_ENV)) {
  const appId = import.meta.env.DATADOG_RUM_APP_ID;
  const clientToken = import.meta.env.DATADOG_RUM_CLIENT_TOKEN;
  const rumEnv = import.meta.env.DATADOG_RUM_ENV;

  const loggerName = 'DataDog RUM init';
  if (!appId) {
    logger.error(loggerName, 'DATADOG_RUM_APP_ID not set');
  } else if (!clientToken) {
    logger.error(loggerName, 'DATADOG_RUM_CLIENT_TOKEN not set');
  } else {
    datadogRum.init({
      applicationId: appId,
      clientToken: clientToken,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.com',
      service: 'horizon',
      env: rumEnv,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: isDemo,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask',
    });
  }
}

// Set the moment locale -- this may need to change when the language is to be settable by the patient/user
SetMomentLocale();

const routes = (
  <Switch>
    <SentryRoute path="/homeRegistration/informationNotice" component={InformationNotice} />
    <SentryRoute path="/homeRegistration/linkExpired" component={LinkExpired} />
    <SentryRoute path="/home-registration/validate/:token" component={HomeRegistrationLogin} />
    <SentryRoute path="/patient/:patientId/covid" component={CovidContainer} />
    <SentryRoute path="/patient/:patientId/distress/:distressId" component={DTContainer} />
    <SentryRoute path="/patient/:patientId/health" component={HAContainer} />
    <SentryRoute
      path="/patient/:patientId/registration"
      component={region === Region.US ? RegistrationUS : RegistrationAU}
    />
    <SentryRoute path="/patient/registrationComplete" component={RegistrationComplete} />
    <SentryRoute path="/patient/:patientId/summary" component={PatientFormSummaryApollo} />
    <SentryRoute path="/patient/:patientId/home" component={PatientNavigationApollo} />
    <SentryRoute path="/patient/:patientId/nurse/prectchartcheck/:formId" component={PreCtChartCheckApollo} />
    <SentryRoute path="/patient/:patientId/nurse/precttriage/:formId" component={PreCtTriageApollo} />
    <SentryRoute path="/patient/:patientId/management" component={ManagementPlan} />
    <SentryRoute path="/patient/create" component={CreatePatient} />
    <SentryRoute path="/patient" component={RegistrationLoginApollo} />
    {/* UK registration */}
    <SentryRoute path="/registration/:patientId/address" component={RegistrationAddressApollo} />
    <SentryRoute path="/registration/:patientId/altcontact" component={UKRegistrationAltContactApollo} />
    <SentryRoute path="/registration/:patientId/basic" component={RegistrationBasicApollo} />
    <SentryRoute path="/registration/:patientId/contact" component={RegistrationContactDetailsApollo} />
    <SentryRoute path="/registration/:patientId/demographics" component={UKRegistrationDemographicsApollo} />
    <SentryRoute path="/registration/:patientId/gp" component={UKRegistrationGPApollo} />
    <SentryRoute path="/registration/:patientId/infonotice" component={RegistrationInformationNoticeApollo} />
    <SentryRoute path="/registration/:patientId/insurance" component={RegistrationInsuranceApollo} />
    <SentryRoute path="/registration/:patientId/attachments" component={RegistrationFileAttachmentsApollo} />
    <SentryRoute path="/registration/:patientId/summary" component={UKRegistrationSummaryApollo} />
    <SentryRoute path="/registration/:patientId/conflicts" component={RegistrationConflictsApollo} />
    {/* Other */}
    <SentryRoute path="/search" component={region === Region.UK ? UKPatientSearch : PSODashboard} />
    <SentryRoute path="/review-forms" component={PSODashboard} />
    <SentryRoute path="/sms-logs" component={PSODashboard} />
    {region === Region.UK && <SentryRoute path="/appointments" component={UKAppointmentsDashboardApollo} />}
    <SentryRoute path="/endSession" component={HomeRegoEndSession} />
    <SentryRoute path="/timeout" component={SessionTimeout} />
    <SentryRoute path="/error" component={ErrorPage} />
    <SentryRoute
      exact
      path="/manual-error"
      component={() => {
        throw new Error('Manual error triggered, please contact support');
      }}
    />
    <SentryRoute path="/app-selector" component={AppSelector} />
    <SentryRoute path="/logout" component={LogoutOP} />
    {/* OPX */}
    <SentryRoute exact path="/login" component={PXLogin} />

    <SentryRoute path="/px">
      <Switch>
        <SentryRoute path="/px/appointments/:id" component={PXAppointments} />
        <SentryRoute path="/px/appointments" component={PXAppointments} />
        <SentryRoute path="/px/mappingupdate" component={MappingUpdate} />
        <SentryRoute path="/px/termsAndConditions" component={PXTermsAndConditions} />
        <SentryRoute path="/px/information/faq/:category" component={PXFAQContentContainer} />
        <SentryRoute path="/px/information/faq" component={PXFAQHome} />
        <SentryRoute path="/px/information/:pageId" component={PXContentContainer} />
        <SentryRoute path="/px/information" component={PXInformation} />
        <SentryRoute path="/px/feedback" component={PXFeedback} />
        <SentryRoute path="/px/details" component={PXPatientDetails} />
        <SentryRoute path="/px/signup" component={PXSignup} />
        <SentryRoute path="/px/changePassword" component={PXChangePassword} />
        <SentryRoute path="/px/forgotPassword" component={PXForgotPassword} />
        <SentryRoute path="/px/resetPassword" component={PXResetPassword} />
        <SentryRoute path="/px/resetLink" component={PXResetLink} />
        <SentryRoute path="/px/linkExpired" component={PXLinkExpired} />
        <SentryRoute path="/px/mfa/" component={PXMultifactorAuth} />
        <SentryRoute path="/px/home" component={PXHome} />
        <SentryRoute path="/px">
          <Redirect to="/px/home" />
        </SentryRoute>
      </Switch>
    </SentryRoute>
    {/* OPX */}

    <SentryRoute path="/navigator">
      <ROBase>
        <Switch>
          <SentryRoute exact path="/navigator/patient/:id/summary" component={ROPatientSummaryPage} />
        </Switch>
      </ROBase>
    </SentryRoute>
    <SentryRoute path="/radiation">
      <ROBase>
        <Switch>
          {CurrentAppConfig.Insights?.enabled && (
            <SentryRoute path="/radiation/insights/" component={InsightsDashboard} />
          )}
          {isDemo && <SentryRoute path="/radiation/patient-tracker/" component={ROPatientTracker} />}
          {isDemo && <SentryRoute path="/:oncologyType/patient/:id/pathway" component={ROPatientPathway} />}
          <SentryRoute path="/:oncologyType/patient/:id/careplan/:careplanId/" component={ROPatientCarePlanPage} />
          <SentryRoute exact path="/:oncologyType/patient/:id/summary" component={ROPatientSummaryPage} />
          <SentryRoute path="/:oncologyType/patient/:id/documents/:page" component={DocumentsPageRouter} />
          <SentryRoute exact path="/:oncologyType/patient/:id/new" component={Summary} />
          <SentryRoute path="/:oncologyType/patient/:id/notes" component={Notes} />
          <SentryRoute path="/:oncologyType/patient/:id/labs" component={ROPatientLabs} />
          <SentryRoute path="/:oncologyType/patient/:id/outcomes/:outcomeId/" component={OutcomeFormPage} />
          <SentryRoute path="/:oncologyType/patient/:id/outcomes" component={OutcomesPage} />
          <SentryRoute
            path="/:oncologyType/patient/:id/on-treatment-review/:reviewId"
            component={OnTreatmentReviewForm}
          />
          <SentryRoute path="/:oncologyType/patient/:id/on-treatment-review" component={OnTreatmentReview} />
          <SentryRoute path="/:oncologyType/registration/patient/:id" component={ROCreatePatient} />
          <SentryRoute path="/:oncologyType" component={Dashboard} />
        </Switch>
      </ROBase>
    </SentryRoute>
    <SentryRoute path="/medonc">
      <MOBase>
        <Switch>
          <SentryRoute path="/:oncologyType/patient/:id/careplan/:careplanId/" component={MOPatientCarePlanPage} />
          <SentryRoute exact path="/:oncologyType/patient/:id/summary" component={MOPatientSummary} />
          <SentryRoute path="/:oncologyType/patient/create" component={MOCreatePatient} />
          <SentryRoute path="/:oncologyType" component={MODashboard} />
        </Switch>
      </MOBase>
    </SentryRoute>
  </Switch>
);

const ErrorModalBoundary = (): JSX.Element => {
  const { setError } = useErrorModalContext();
  useEffect(() => {
    setError('Generic');
  }, [setError]);
  return <></>;
};

const WrappedApp = (): JSX.Element => {
  const [theme, setTheme] = useState(defaultTheme);

  return (
    <React.StrictMode>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <Favicon url={theme?.custom?.favicon} />
        <Suspense fallback={<div />}>
          <ThemeProvider theme={theme}>
            <ErrorModalContextProvider>
              <ApolloProviderWithError>
                <Sentry.ErrorBoundary fallback={<ErrorModalBoundary />}>
                  <NavigationContextProvider>
                    <AssessmentProvider>
                      <RegistrationContextProvider>
                        <Refresh />
                        <BrowserRouter>
                          <AppVersion />
                          <SentryUser />
                          <DatadogUserInfo />
                          <StyledEngineProvider injectFirst>
                            <FormContextProvider>
                              {region === Region.UK && <UKHomePage routes={routes} />}
                              {region !== Region.UK && routes}
                              <Route exact path="/" component={SSOPage} />
                            </FormContextProvider>
                          </StyledEngineProvider>
                        </BrowserRouter>
                      </RegistrationContextProvider>
                    </AssessmentProvider>
                  </NavigationContextProvider>
                </Sentry.ErrorBoundary>
              </ApolloProviderWithError>
            </ErrorModalContextProvider>
          </ThemeProvider>
        </Suspense>
      </ThemeContext.Provider>
    </React.StrictMode>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<WrappedApp />);
