// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Typography } from '@mui/material';
import { StandardDialog } from 'shared-components/components';
import { getFormattedDateTime } from 'op-pages/RO/Dashboard/CareplanTable/helper';
interface Props {
  isOpen: boolean;
  dismissFunction: () => void;
  submitFunction: () => void;
  modalType: 'cancel' | 'revert';
  submittedAt?: string;
}
const DiscardMessage = ({ submittedAt }: { submittedAt?: string }): JSX.Element => {
  const formattedSubmittedAt = submittedAt ? getFormattedDateTime(submittedAt).toLocaleString() : '';
  return (
    <>
      <div>
        You'll lose all CPOT changes, and revert to the last prescription version, submitted {formattedSubmittedAt}.
      </div>{' '}
      <div>Are you sure you want to discard your changes?</div>
    </>
  );
};

const RevertMessage = (): JSX.Element => {
  return (
    <div>Are you sure you want to undo CPOT and revert to the last submitted prescription prior to treatment?</div>
  );
};

const ModalCpotCancel = (props: Props): JSX.Element => {
  const { isOpen, dismissFunction, submitFunction, modalType, submittedAt } = props;
  const header = modalType === 'cancel' ? 'Discard CPOT Changes?' : 'Revert to Previous Prescription';
  const body = modalType === 'cancel' ? <DiscardMessage submittedAt={submittedAt} /> : <RevertMessage />;
  const submitText = modalType === 'cancel' ? 'Discard' : 'Revert';
  return (
    <StandardDialog
      title={header}
      open={isOpen}
      onClose={dismissFunction}
      submitText={submitText}
      destructiveSubmit={true}
      onSubmit={() => {
        submitFunction();
        dismissFunction();
      }}>
      <Typography paddingY={2}>{body}</Typography>
    </StandardDialog>
  );
};

export default ModalCpotCancel;
